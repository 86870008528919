import { types, Badge, helpers } from "@vilocnv/allsetra-core";
import { isEmpty, omit } from "lodash";
import { formatDate } from "./commonHelpers";
import * as Yup from "yup";
import moment from "moment";
import { DateTime } from "luxon";
import { checkValidDate } from "../constants";
import { Box, Stack } from "@mui/material";
import { IObjectType, IUpdateDeviceSubscriptionInitialValues } from "../types";
import { DetailsButtonText } from "components/sections/devices/DeviceDetailsBody/DeviceDetailsBody.styled";
//
// OBJECT DETAILS PAGE HELPERS
//
export const transformObjectForObjectInfoTable = (
  object: types.IObject | null,
  navigate: any
): any => {
  if (!object) return [];
  // @ts-ignore
  const lastReportedMileage = object?.lastReportedMileage;
  // @ts-ignore
  const lastReportedMileageDate = object?.lastReportedMileageDate;
  return [
    [
      "Assigned Accounts",
      object?.accounts?.length > 0
        ? object?.accounts?.map((acc: any, i: number) => (
            <DetailsButtonText
              id={`object-assignedAccount-${i}`}
              onClick={() =>
                navigate("/dashboard/account-manager/details/" + acc?.uniqueId)
              }
            >
              {`→ ${acc?.name}`}
            </DetailsButtonText>
          ))
        : "N/A",
    ],
    [
      "Assigned Users",
      object?.users?.length > 0
        ? object?.users?.map((item) => item?.user?.email).join(", ")
        : "N/A",
    ],
    [
      "Alarm Owner",
      <DetailsButtonText
        id={`object-alarm-owner`}
        onClick={() =>
          navigate(
            "/dashboard/account-manager/details/" + object?.alarmOwner?.uniqueId
          )
        }
      >
        {"→ " + object?.alarmOwner?.name || "N/A"}
      </DetailsButtonText>,
      checkValidDate(object?.alarmOwner?.lastUpdated)
        ? `${moment(object?.alarmOwner?.lastUpdated).fromNow()} (${moment(
            object?.alarmOwner?.lastUpdated
          ).format("MMMM DD, YYYY HH:mm")})`
        : "N/A",
    ],
    [
      "Invoice Owner",
      <DetailsButtonText
        id={`object-invoice-owner`}
        onClick={() =>
          navigate(
            "/dashboard/account-manager/details/" +
              object?.invoiceOwner?.uniqueId
          )
        }
      >
        {"→ " + object?.invoiceOwner?.name || "N/A"}
      </DetailsButtonText>,
      checkValidDate(object?.invoiceOwner?.lastUpdated)
        ? `${moment(object?.invoiceOwner?.lastUpdated).fromNow()} (${moment(
            object?.invoiceOwner?.lastUpdated
          ).format("MMMM DD, YYYY HH:mm")})`
        : "N/A",
    ],
    [
      "Object ID",
      object?.aNumber || "N/A",
      checkValidDate(object.lastUpdated)
        ? `${moment(object.lastUpdated).fromNow()} (${moment(
            object.lastUpdated
          ).format("MMMM DD, YYYY HH:mm")})`
        : "N/A",
    ],
    [
      "Multiviewer Name",
      object?.multiviewerName || "N/A",
      checkValidDate(object.lastUpdated)
        ? `${moment(object.lastUpdated).fromNow()} (${moment(
            object.lastUpdated
          ).format("MMMM DD, YYYY HH:mm")})`
        : "N/A",
    ],
    [
      "Object Type",
      object?.objectType?.name || "N/A",
      checkValidDate(object.lastUpdated)
        ? `${moment(object.lastUpdated).fromNow()} (${moment(
            object.lastUpdated
          ).format("MMMM DD, YYYY HH:mm")})`
        : "N/A",
    ],
    [
      "Comments",
      object?.comments || "N/A",
      checkValidDate(object.lastUpdated)
        ? `${moment(object.lastUpdated).fromNow()} (${moment(
            object.lastUpdated
          ).format("MMMM DD, YYYY HH:mm")})`
        : "N/A",
    ],
    [
      "Mileage",
      // @ts-ignore
      lastReportedMileage,
      checkValidDate(lastReportedMileageDate)
        ? `${moment(lastReportedMileageDate).fromNow()} (${moment(
            lastReportedMileageDate
          ).format("MMMM DD, YYYY HH:mm")})`
        : "N/A",
    ],
  ];
};

export const getBadgeForAlarmConfiguration = (
  isEnabled: boolean,
  isSupported: boolean,
  optionalFilters: any[]
) => (
  <Stack direction={"row"} flexWrap={"wrap"} gap={1}>
    {isSupported ? (
      <Box>
        <Badge colorScheme={isEnabled ? "success" : "error"}>
          {isEnabled ? "Enabled" : "Disabled"}
        </Badge>
      </Box>
    ) : null}
    <Badge colorScheme={isSupported ? "info" : "error"}>
      {isSupported ? "Supported" : "Not Supported"}
    </Badge>
    {optionalFilters?.map((item) => (
      <Box>
        <Badge colorScheme={"grey"}>{item?.name}</Badge>
      </Box>
    ))}
  </Stack>
);

export const transformObjectForAlarmConfigTable: any = (
  alarmConfig: any,
  activeObject: IObjectType
): any => {
  const awayModeRow = [
    "Away Mode",
    <Box key="away-mode">
      <Badge colorScheme={activeObject?.awayModeEnabled ? "success" : "error"}>
        {activeObject?.awayModeEnabled ? "Enabled" : "Disabled"}
      </Badge>
    </Box>,
  ];

  if (isEmpty(alarmConfig?.effectiveConfiguration)) return [awayModeRow];

  const configRows = alarmConfig?.effectiveConfiguration.map((item: any) => [
    item?.alarmType?.name,
    getBadgeForAlarmConfiguration(
      item?.isEnabled,
      item?.isSupported,
      item?.optionalFilters
    ),
    checkValidDate(item?.alarmType.lastUpdated)
      ? `${moment(item?.alarmType.lastUpdated).fromNow()} (${moment(
          item?.alarmType.lastUpdated
        ).format("MMMM DD, YYYY HH:mm")})`
      : "N/A",
  ]);
  return [awayModeRow, ...configRows];
};

export const transformObjectMetaDataForDynamicFields = (
  object: types.IObject | null
): any => {
  if (!object) return [];
  const data: any = [];

  object?.metadata?.forEach((item: any) => {
    data.push([
      item?.field?.label || "N/A",
      item?.value || "N/A",
      checkValidDate(item?.reportedOn)
        ? `${moment(item?.reportedOn).fromNow()} (${moment(
            item?.reportedOn
          ).format("MMMM DD, YYYY HH:mm")}) by ${item?.reportedBy.firstName} ${
            item?.reportedBy.lastName
          }
      `
        : "N/A",
    ]);
  });

  return data;
};

export const transformObjectMetaDataForInstallationInformation = (
  object: types.IObject | null
): any => {
  if (!object) return [];
  const installationInfo = object?.metadata?.filter(
    (item) => item?.informationType === "INSTALLATION"
  );
  const data: any = [];

  installationInfo?.forEach((item: any) => {
    data.push([
      item?.field.label,
      item?.value || "N/A",
      checkValidDate(item?.reportedOn)
        ? `${moment(item?.reportedOn).fromNow()} (${moment(
            item?.reportedOn
          ).format("MMMM DD, YYYY HH:mm")}) by ${item?.reportedBy.firstName} ${
            item?.reportedBy?.lastName
          }
      `
        : "N/A",
    ]);
  });

  return data;
};

export const transformObjectMetaDataForService = (
  object: types.IObject | null,
  objectSubscriptions: any
): any => {
  if (isEmpty(object) || isEmpty(objectSubscriptions)) return [];
  const serviceFields = object.metadata.filter(
    (item) => item.informationType === "SERVICE"
  );

  const data: any = [];

  const objectData: any = {
    "Serial Number": objectSubscriptions?.device?.serialNumber,
    "Subscription Name": objectSubscriptions?.subscription?.name,
    "Subscription Start": checkValidDate(objectSubscriptions?.startDate)
      ? `${moment(objectSubscriptions?.startDate).fromNow()} (${moment(
          objectSubscriptions?.startDate
        ).format("MMMM DD, YYYY HH:mm")})`
      : "N/A",
    "Invoicing End Date": checkValidDate(objectSubscriptions?.invoicingEndDate)
      ? `${moment(objectSubscriptions?.invoicingEndDate).fromNow()} (${moment(
          objectSubscriptions?.invoicingEndDate
        ).format("MMMM DD, YYYY HH:mm")})`
      : "N/A",
    "Device Access End Date": checkValidDate(
      objectSubscriptions?.deviceAccessEndDate
    )
      ? `${moment(
          objectSubscriptions?.deviceAccessEndDate
        ).fromNow()} (${moment(objectSubscriptions?.deviceAccessEndDate).format(
          "MMMM DD, YYYY HH:mm"
        )})`
      : "N/A",
    "Service Name": objectSubscriptions?.serviceName,
    "Price per month": `€ ${objectSubscriptions?.subscription?.valuePerMonth}`,
    Duration: `${objectSubscriptions?.subscription?.contractTerm} months`,
  };

  Object.keys(objectData).map((item: string) =>
    data.push([item, objectData[item]])
  );
  data.push([
    "Subscription Status",
    objectSubscriptions?.subscriptionStatus,
    "",
    true,
  ]);

  serviceFields.forEach((item: any) => {
    data.push([
      item?.field.label,
      item?.value || "N/A",
      checkValidDate(item.reportedOn)
        ? `${moment(item.reportedOn).fromNow()} (${moment(
            item.reportedOn
          ).format("MMMM DD, YYYY HH:mm")}) by ${item?.reportedBy.firstName} ${
            item?.reportedBy.lastName
          }
      `
        : "N/A",
    ]);
  });
  return data;
};

export const updateDeviceSubscriptionInitialValues: IUpdateDeviceSubscriptionInitialValues =
  {
    serviceId: "",
    subscriptionId: "",
  };

export const updateDeviceSubscriptionValidationSchema: Yup.Schema = Yup.object({
  serviceId: Yup.string().required().label("Service Id"),
  subscriptionId: Yup.string().required().label("Subscription Id"),
});

export const objectDeleteInitialValues = {
  deviceAccessEndDate: "",
  invoicingEndDate: "",
};

export const objectDeleteValidationSchema: Yup.Schema = Yup.object({
  deviceAccessEndDate: Yup.string().required().label("Device End Date"),
  invoicingEndDate: Yup.string().required().label("Invoice End Date"),
});

export const transformObjectForSubscriptionInfoTable = (object: any): any => {
  if (!object) return {};

  return {
    "Service Name": object.serviceName || "N/A",
    "Subscription Name": object.subscription.name || "N/A",
    Price: object.serviceName || "N/A",
    "Subscription Type": object.subscriptionType || "N/A",
    "Contract Term": `${object.subscription.contractTerm} year`,
    "Prolongation Term": `${object.subscription.prolongationInMonths} ${
      object.subscription.prolongationInMonths > 1 ? "months" : "month"
    }`,
    "Termination Term": `${object.subscription.terminationInMonths} ${
      object.subscription.terminationInMonths > 1 ? "months" : "month"
    }`,
    "Subscription Start": formatDate(object.startDate),
    "Expected subscription end": formatDate(object.endDate),
  };
};

//
// OBJECT SETTINGS PAGE HELPERS
//
export const objectDetailsFormatterForSettingsForm = (object: any) => {
  if (isEmpty(object)) return {};

  const removedUnwantedKeys = omit(object, [
    "created",
    "createdBy",
    "deleted",
    "deletedBy",
    "installations",
    "isDeleted",
    "lastUpdated",
    "location",
    "status",
    "updatedBy",
    "objectType",
    "owner",
    "devices",
    "isWorkingHoursOverriden",
  ]);

  const formattedObject: any = {
    ...removedUnwantedKeys,
    objectTypeId: object.objectType?.uniqueId ?? "",
    alarmOwnerId: object.alarmOwner?.uniqueId ?? "",
    invoiceOwnerId: object.invoiceOwner?.uniqueId ?? "",
    accounts: object?.accounts?.map((item: any) => item.uniqueId),
    users: object?.users?.map((item: any) => item?.user?.uniqueId),
    groups: object?.groups?.map((item: any) => item.uniqueId),
    hasCustomAlarmConfiguration: Boolean(object?.hasCustomAlarmConfiguration),
    isWorkingHoursOverriden: Boolean(object?.isWorkingHoursOverriden),
    workingHours: {
      // @ts-ignore
      workingHoursSchedule: object?.workingHours,
    },
    workingHoursType:
      object?.workingHours?.length === 1
        ? "FULLWEEK"
        : object?.workingHours?.length === 5
        ? "WEEKDAYS"
        : "CUSTOM",
  };

  object?.metadata?.length &&
    object?.metadata.forEach((metadata: any) => {
      formattedObject[metadata.field.label] = metadata.value || "";
    });

  return formattedObject;
};

export const transformWorkingHours = (object: types.IObject | null): any => {
  if (isEmpty(object) || isEmpty(object)) return [];

  const workingHours: any = [];
  const data: any = object?.isWorkingHoursOverriden
    ? object?.workingHours
    : object?.alarmOwner?.workingHours;

  if (data.length > 0) {
    data?.map((item: any) =>
      workingHours.push([
        DateTime.fromObject({
          weekday: item?.dayOfWeek == 0 ? "7" : item?.dayOfWeek,
        }).toFormat("cccc"),
        `${item?.startTime} - ${item?.endTime}`,
      ])
    );
  } else {
    for (let i = 0; i < 7; i++) {
      workingHours.push([
        DateTime.fromObject({
          //@ts-ignore
          weekday: i == 0 ? 7 : i,
        }).toFormat("cccc"),
        `-- : --`,
      ]);
    }
  }

  return workingHours;
};

export const getNewAccountInfo = (object: types.IObject | null): any => {
  const installationInfo: any = object?.installations[0] || {};
  const data: any = [];
  data.push(["New Account Email", installationInfo?.newAccountEmail || "N/A"]);
  data.push(["New Account Name", installationInfo?.newAccountName || "N/A"]);
  data.push(["New Account Phone", installationInfo?.newAccountPhone || "N/A"]);
  return data;
};

export const objectDetailsValidationSchema = Yup.object({
  name: Yup.string().required().label("Object Name"),
  hasCustomAlarmConfiguration: Yup.boolean(),
  isWorkingHoursOverriden: Yup.boolean(),
  objectTypeId: Yup.string().required().label("Object Type"),
  accounts: Yup.array().of(Yup.string()).label("Assigned accounts"),
  users: Yup.array().of(Yup.string()).label("Assigned users"),
  alarmOwnerId: Yup.string().required().label("Alarm Owner"),
  invoiceOwnerId: Yup.string().required().label("Invoice Owner"),
  aNumber: Yup.string().nullable().label("A-Number"),
  multiviewerName: Yup.string().nullable().label("Multiviewer"),
  mileage: Yup.number().nullable().label("Milage"),
  comments: Yup.string().nullable(),
  metadata: Yup.array().nullable(),
  remindersFrom: Yup.number().nullable().label("Reminders From"),
  remindersForEvery: Yup.number().nullable().label("Reminders For Every"),
  reminderEmail: Yup.string().nullable().label("Reminder Email"),
  reminderName: Yup.string().nullable().label("Reminder Name"),
  alarmsConfiguration: Yup.object().nullable(),
  notifications: helpers.notificationsConfigurationValidationSchema,
  workingHoursType: Yup.string().nullable().label("Working Hours Type"),
  workingHours: Yup.object({
    workingHoursSchedule: helpers.workingHoursValidationSchema,
  }),
  uniqueId: Yup.string(),
});

export const objectsMetadataFormatterForAPI = (values: any) => {
  const transformedMetadata: any = [];
  const fieldsNames: string[] = [];

  values?.metadata &&
    values?.metadata?.length &&
    values?.metadata?.forEach((item: any) => {
      if (item?.field?.label in values) {
        transformedMetadata.push({
          fieldUniqueId: item.field.uniqueId,
          value: values[item.field.label],
        });
        fieldsNames.push(item.field.label);
      }
    });

  return omit({ ...values, metadata: transformedMetadata }, fieldsNames);
};

export interface IObjectCorrection {
  correctionDate: string;
  newMileage: number;
}

export const objectMileageCorrectionSchema = Yup.object().shape({
  newMileage: Yup.number()
    .min(1, "Mileage cannot be negative")
    .required("Mileage is required"),
  correctionDate: Yup.string().required("Correction Date is required"),
});

export const objectMileageCorrectionInitialValues = {
  newMileage: 0,
  correctionDate: "",
};
